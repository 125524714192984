export function useProductAvailabilityTime() {
    const getDays = (date: string): null | number => {
        return calculateTime(date, 'days');
    };

    const getWeeks = (date: string): null | number => {
        return calculateTime(date, 'weeks');
    };

    const calculateTime = (date: string, unit: "days" | "weeks"): null | number => {
        const nowDate: Date = new Date();
        const availabilityDate: Date = new Date(date);

        const nowTimestamp: number = nowDate.getTime();
        const availabilityTimestamp: number = availabilityDate.getTime();

        if (nowTimestamp < availabilityTimestamp) {
            const calculdatedTime: Date = new Date(availabilityTimestamp - nowTimestamp);

            const daysPassed: number = Number(Math.abs(calculdatedTime.getDate()) - 1);
            const monthsPassed: number = Number(Math.abs(calculdatedTime.getMonth() + 1) - 1);
            const yearsPassed: number = Number(Math.abs(calculdatedTime.getFullYear()) - 1970);

            const totalDays: number = (yearsPassed * 365) + (monthsPassed * 30.417) + daysPassed;
            const totalWeeks: number = ( totalDays >= 7 ) ? totalDays / 7 : 0;

            return Math.ceil(unit === 'days' ? totalDays : totalWeeks);
        }

        return null;
    }

    return {
        getDays,
        getWeeks,
    };
}