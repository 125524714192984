<script setup lang="ts">
import { useProductAvailabilityTime } from "~/composables/useProductAvailabilityTime";

const props = withDefaults(
  defineProps<{
    availableStock: number;
    nextAvailablityDate?: string | undefined,
    statusCode?: string | undefined;
    showHint?: boolean;
  }>(),
  {
    nextAvailablityDate: undefined,
    statusCode: undefined,
    showHint: false,
  }
);

const { getWeeks } = useProductAvailabilityTime();
const availabilityInWeeks = computed(() => getWeeks(props.nextAvailablityDate));
</script>

<template>
  <span v-bind="$attrs" class="block font-bold">
    <span v-if="availableStock >= 3" class="text-maas-brand-success">Sofort lieferbar</span>
    <span v-else-if="availableStock <= 2 && availableStock >= 1" class="text-maas-brand-warning">Nur noch wenig auf Lager</span>
    <template v-else>
      <template v-if="statusCode === '02'">
        <span v-if="nextAvailablityDate && availabilityInWeeks" class="text-maas-brand-info">Voraussichtlich lieferbar in {{ availabilityInWeeks }} {{ availabilityInWeeks > 1 ? 'Wochen' : 'Woche' }}</span>
        <template v-else>Leider nicht mehr auf Lager</template>
      </template>
      <template v-else-if="statusCode === '08'">Leider nicht mehr auf Lager</template>
      <span v-else-if="statusCode === '04' || statusCode === '06' || statusCode === '07'" class="text-maas-brand-danger">Leider bereits ausverkauft</span>
    </template>
  </span>
  <span v-if="showHint" class="block text-small text-maas-typography-text-grey-medium">
    <template v-if="availableStock >= 1">Versandfertig innerhalb von 1-3 Werktagen</template>
    <template v-else-if="statusCode === '02'">Jetzt bestellen – bald wieder erhältlich</template>
    <template v-else-if="statusCode === '08'">Evtl. aus Filialbeständen lieferbar</template>
  </span>
</template>
